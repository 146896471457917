<template>
  <div v-if="isLoading">
    <aom-skeleton-loader />
  </div>
  <validation-observer
    v-else
    ref="observer"
    v-slot="{ invalid, handleSubmit }"
  >
    <b-card>
      <h3 class="text-capitalize mb-2">
        Edit {{ roleAlias }} Application
      </h3>
      <h5 class="mt-2">
        Progress {{ currentCompletePercent }}% , question {{ currentQuestionNumber + 1 }} of {{ totalQuestions }}
      </h5>
      <b-progress
        class="mb-1"
        :value="currentCompletePercent"
        variant="primary"
        animated
      />
      <template v-if="currentQuestion.type_id === modelQuestionTypes.TEXT">
        <aom-application-single-text-box
          :question.sync="currentQuestion"
          :index="currentQuestionNumber"
        />
      </template>
      <template v-if="currentQuestion.type_id === modelQuestionTypes.CHOICE">
        <aom-application-choice
          :question.sync="currentQuestion"
          :index="currentQuestionNumber"
        />
      </template>
      <template v-if="currentQuestion.type_id === modelQuestionTypes.MATCHING">
        <aom-application-multi-choice-matching
          :question.sync="currentQuestion"
          :index="currentQuestionNumber"
        />
      </template>
      <template v-if="currentQuestion.type_id === modelQuestionTypes.SCALE">
        <aom-application-rating
          :question.sync="currentQuestion"
          :index="currentQuestionNumber"
        />
      </template>
      <b-row>
        <b-col
          sm="12"
          md="4"
          class="mb-3"
        >
          <b-button
            v-if="previousIndex >= 0"
            block
            variant="primary"
            :disabled="isPreviousEnabled"
            @click="onPrevious()"
          >
            Previous
          </b-button>
        </b-col>
        <b-col
          sm="12"
          md="8"
          offset-sm="0"
        >
          <b-row class="w-1 d-flex justifiy-content-end mr-0 ml-0">
            <b-col
              sm="12"
              md="7"
              lg="5"
              offset-sm="0"
              offset-lg="2"
              class="save-continue-wrapper mb-1"
            >
              <b-form-checkbox
                v-if="!isLastQuestionToAnswer"
                id="save-and continue"
                v-model="saveAndContinue"
                name="save-and-conntinue"
                value="true"
              >
                Save and Continue Later
              </b-form-checkbox>
            </b-col>
            <b-col 
              sm="12"
              md="5"
              class="pr-0"
            >
              <b-button
                v-if="!saveAndContinue && !isApplicationComplete && !isLastQuestionToAnswer"
                block
                variant="primary"
                :disabled="invalid"
                @click="onNext()"
              >
                Next
              </b-button>
              <b-button
                v-if="saveAndContinue || isApplicationComplete || isLastQuestionToAnswer"
                block
                variant="primary"
                :disabled="invalid"
                @click="handleSubmit(submitApplication)"
              >
                <b-spinner
                  v-if="isSumitting"
                  small
                />
                {{ buttonText }}
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>
  </validation-observer>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import {
  BRow,
  BCol,
  BButton,
  BSpinner,
  BProgress,
  BFormCheckbox, 
  BCard
} from "bootstrap-vue";
import { questionTypes as modelQuestionTypes } from '@/models/questionTypes';
import { ValidationObserver } from "vee-validate";
//eslint-disable-next-line
import { required } from "@validations";
import { makeErrorToast, makeSuccessToast } from "@/libs/utils";
import AomSkeletonLoader from "@aom-core/AomSkeletonLoader.vue";
import { userRoles, userRolesDisplay } from '@/models';
import _isEqual from 'lodash/isEqual';
import AomApplicationSingleTextBox from '@aom-core/AomApplicationSingleTextBox.vue';
import AomApplicationChoice from '@aom-core/AomApplicationChoice.vue';
import AomApplicationMultiChoiceMatching from '@aom-core/AomApplicationMultiChoiceMatching.vue';
import AomApplicationRating from '@aom-core/AomApplicationRating.vue';

export default {
  name: "ApplicationEditStep",
  components: {
    BRow,
    BCol,
    BButton,
    BSpinner,
    BCard,
    AomSkeletonLoader,
    ValidationObserver,
    BProgress,
    BFormCheckbox,
    AomApplicationSingleTextBox,
    AomApplicationChoice,
    AomApplicationMultiChoiceMatching,
    AomApplicationRating, 
  },

  data() {
    return {
      messageError: null,
      messageErrorOther: null,
      newAnswers: [],
      modelQuestionTypes: modelQuestionTypes,
      ownApplication: null,
      isLoading: false,
      isSumitting: false,
      applicationEditStorage: null,
      saveAndContinue: false
    };
  },
  computed: {
    ...mapGetters("app", { appCurrentProgram: "currentProgram", isParticipantPortal: "isParticipantPortal"}),
    ...mapGetters("profile", ["profile"]),
    ...mapGetters("participants", [
      "answerData", 
      "completedPercentMentee", 
      "completedPercentMentor", 
      'completePercentageApplication',
      'completePercentageApplicationForRequiredQuestion',
      'applicationMentee'
    ]),
    isPreviousEnabled () {
      return this.previousIndex < 0;
    },
    currentIndex () {
      return this.displayQuestions.findIndex(q => q.id === this.currentQuestion.id);
    },
    previousIndex () {
      return this.currentIndex - 1;
    },
    applicationSetId() {
      const program = this.$store.getters['participants/program'];
      if (program && program.application_set) {
        return program.application_set.id;
      }
      return undefined;
    },

    currentQuestion() {
      if (!this.answerData) {
        return null;
      }
      const questionId = this.$route.params.questionId;
      if (!questionId) {
        return null;
      }

      return this.answerData.questions.find(
        question => Number(question.id) === Number(questionId)
      );
    },
    pageHeader() {
      return 'Edit Application';
    },
    roleNameFromRoute () {
      return Number(this.$route.params.role);
    },
    roleAlias () {
      return this.appCurrentProgram.program_roles.find(r => r.role_id === this.roleNameFromRoute)?.alternative_role_name || userRolesDisplay[this.roleNameFromRoute];
    },
    displayQuestions() {
      if (this.answerData && this.answerData.questions) {
        return this.answerData.questions.filter(q => q.type_id !== modelQuestionTypes.SCALE || !q.parent_id);
      }
      return [];
    },
    isUserMentee () {
      return this.profile.roles.includes(userRoles.MENTEE);
    },
    isUserMentor () {
      return this.profile.roles.includes(userRoles.MENTOR);
    },
    isMenteeApplicationComplete () {
      return Boolean(this.completedPercentMentee === 100);
    },
    isMentorApplicationComplete () {
      return Boolean(Number(this.completedPercentMentor) === 100);
    },
    currentCompletePercent () {
      return Number(this.completePercentageApplication); 
    },
    totalQuestions () {
      return this.displayQuestions.length;
    },
    currentQuestionNumber() {
      return this.displayQuestions.findIndex(q => q.id === this.currentQuestion.id);
    },
    buttonText () {
      return (this.currentCompletePercent === 100 || this.isLastQuestionToAnswer) ?
        'Submit' : 
        'Save and Close';
    },
    isApplicationComplete () {
      let nextQuestion = this.firstUnanswerQuestion();
      return (Number(this.currentCompletePercent) === 100 || !this.currentQuestion.is_required) && !nextQuestion;
    },
    isLastQuestionToAnswer () {
      return Number(this.currentIndex + 1) === Number(this.totalQuestions);
    },
    menteeFirstQuestionId () {
      return Number(this.applicationMentee?.questions[0]?.id);
    },
    isMenteeApplication() {
      return this.roleNameFromRoute === userRoles.MENTEE;
    },
    isMentorApplication() {
      return this.roleNameFromRoute === userRoles.MENTOR;
    },
    questionTypeId () {
      return this.currentQuestion.type_id;
    }
  },
  watch : {
    answerData(n) {
      if(n) {
        if(this.applicationEditStorage && this.applicationEditStorage.currentQuestion >= n.questions.length) {
          this.currentQuestionNumber = 0;   
        }
      }
    },
    '$route.path': {
      handler (n, o) {
        if(_isEqual(n, o)) {
          return;
        }
        if(n) {
          this.loadApplication();
        }
      }
    },
    '$route.params.questionId': {
      handler(n) {
        if(n) {
          this.newAnswers = [];
        }
      },
      immediate: true
    }
  },
  created() {
    if(this.isUserMentee) {
      this.loadApplicationAnswersForMentee();
    }
    this.loadApplication();
    if (JSON.parse(localStorage.getItem('application_edit_step_position'))) {
      this.applicationEditStorage = JSON.parse(localStorage.getItem('application_edit_step_position'))[this.appCurrentProgram.id][this.roleNameFromRoute];
    }
  },
  methods: {
    ...mapMutations('participants', ['SET_QUESTION_ANSWER', 'SET_QUESTION_STATEMENT_ANSWER']),
    async loadApplication(isForce = false) {
      try {
        if (!isForce) {
          this.isLoading = true;
        }

        if (this.isParticipantPortal &&
          this.appCurrentProgram &&
          this.appCurrentProgram.id &&
          this.applicationSetId
        ) {
          this.ownApplication = await this.$store.dispatch("participants/FETCH_OWN_APPLICATION_SETS", {
            programId: this.appCurrentProgram.id,
            applicationSetId: this.applicationSetId,
            role: this.roleNameFromRoute
          });

          if (this.ownApplication) {
            await this.$store.dispatch("participants/FETCH_OWN_APPLICATION_ANSWERS", {
              programId: this.appCurrentProgram.id,
              formId: this.ownApplication.id,
              userId: this.profile.id
            });
          }
        }
      } catch (e) {
        console.log(e);
        this.$log.error(e);
        this.$toast(makeErrorToast(e));
      } finally {
        this.isLoading = false;
      }
    },
    makeRequestParams() {
      const params = {};
      if (this.appCurrentProgram && this.appCurrentProgram.id) {
        params.programId = this.appCurrentProgram.id;
      }
      if (this.ownApplication) {
        params.formId = this.ownApplication.id;
      }
      params.userId = this.profile.id;
      return params;
    },

    getAnswerData() {
      if(!this.currentQuestion.is_required) {
        if (this.questionTypeId === modelQuestionTypes.CHOICE) {
          if (!this.currentQuestion.answers.length || (this.currentQuestion.answers.length && !this.currentQuestion.answers[0].choice_id)) {
            this.currentQuestion.answers = [
              {
                question_id: this.currentQuestion.id,
                choice_id: null,
              }
            ];
          }
        }
        
        if(this.questionTypeId === modelQuestionTypes.TEXT) {
          if (!this.currentQuestion.answers.length) {
            this.currentQuestion.answers = [
              {
                question_id: this.currentQuestion.id,
                answer_text: "",
              }
            ];
          }
        }
      }
      
      if(this.questionTypeId === modelQuestionTypes.SCALE) {
        return { answers: this.currentQuestion.statements.map(statement => {
          let answer = statement.answers[0];
          if (!answer && !this.currentQuestion.is_required) {
            answer = {
              'choice_id': null,
              'question_id': statement.id,
            };
          }
          return answer;
        })};
      }
      return { answers: this.currentQuestion.answers };
    },
    async checkIfEnrolementStep() {
      const h = this.$createElement;
      const messageVNode = h('div', { class: ['foobar'] }, [
        h('p', { class: ['text-left'] }, [
          ' Your application is in progress and has been ',h('strong', 'submitted incomplete. '),'You must complete it before you can be considered for matching. Please check your email inbox for a confirmation email. If it is in your spam folder, please add artofmentoring.net as a Safe Sender so you will receive emails in your regular inbox. If not there either, contact your IT department to ensure that domains ', h('strong','artofmentoring.net, aomapps.au and aomapps.com'),' are enabled for your company firewall. ',
        ]),        
      ]);
      this.$bvModal
        .msgBoxConfirm([messageVNode], {
          title: 'Your Account Status',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Complete Application',
          cancelTitle: 'Exit',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(async value => {
          await this.saveAnswer();
          if(value) {
            this.saveAndContinue = false;
            this.routeToLowestUnAnsweredQuestion(false);
          }
          if(!value) {
            if(this.isUserMentor && this.isUserMentee) {
              this.redirecToMentorOverview();
            }
            if(this.isUserMentee) {
              this.redirectToMenteeOverview();
            }
            if(this.isUserMentor) {
              this.redirecToMentorOverview();
            }
            
          }
        });
    },
    validateQuestion () {
      if(this.newAnswers.length === 0) {
        this.messageError = 'Please enter a response';
        return false;
      }
      return true;    
    },
    async saveAnswer() {
      try {
        this.isSumitting = true;
        const params = this.makeRequestParams();
        const data = this.getAnswerData();
        await this.$store.dispatch("participants/SAVE_OWN_APPLICATION_ANSWERS", {
          params,
          data
        }); 
      } catch (e) {
        console.log(e);
        this.$toast(makeErrorToast("Something went wrong. Answer not saved."));
        this.$log.error(e);
      } finally {
        this.isSumitting = false;
      }
    },

    onRedirect() {
      if (this.roleNameFromRoute === userRoles.MENTOR) {
        this.redirecToMentorOverview();
      }
      if (this.roleNameFromRoute === userRoles.MENTEE){
        this.redirectToMenteeOverview();
      }
    },
    redirectToMenteeApplication() {
      if(this.$route.name === 'participant-application-mentee') {
        return;
      }
      this.$router.push({name: "participant-application-mentee"});
    },
    redirecToMentorOverview() {
      this.$router.push({name: "participant-application-mentor-overview"});
    },
    redirectToMenteeOverview() {
      this.$router.push({name: "participant-application-mentee-overview"});
    },
    onPrevious() {
      const { id } = this.displayQuestions[this.previousIndex];
      const query = Object.assign({}, this.$route.query);
      const routeName = Number(this.$route.params.role) === Number(userRoles.MENTOR) ? 'participant-application-mentor' : 'participant-application-mentee';
      this.$router.push({ name: routeName, params: {questionId: Number(id), role: this.$route.params.role}, query });
    },
    async onNext() {
      await this.saveAnswer();
      this.routeToLowestUnAnsweredQuestion(false);
    },
    async submitApplication() {
      if(this.$route.query.enrolement && !this.isLastQuestionToAnswer) {
        await this.checkIfEnrolementStep();
      } else {
        await this.saveAnswer();
        if(
          (this.isUserMentee && this.isUserMentor) && 
            !this.isMenteeApplicationComplete && 
            this.$route.query.enrolement && 
            this.isMentorApplication) {
          const query = Object.assign({}, this.$route.query);
          this.$router.replace({name: 'participant-application-mentee', params: { role: userRoles.MENTEE, questionId: this.menteeFirstQuestionId }, query});
          return;
        }
        if(this.isApplicationComplete) {
          this.showApplicationCompleteModal();
        }
        if(this.saveAndContinue) {
          this.onRedirect();
        }
      }
    },
    showApplicationCompleteModal() {
      this.$toast(makeSuccessToast(`Thank you, you have now completed your ${this.roleAlias} application.`));
      const h = this.$createElement;
      const messageVNode = h('div', { class: ['foobar'] }, [
        h('p', { class: ['text-left'] }, [
          ' Great. Your application is complete and ',h('strong', 'has been submitted. '),'Please check your email inbox for a confirmation email. If it is in your spam folder, please add artofmentoring.net as a Safe Sender so you will receive emails in your regular inbox. If not there either, contact your IT department to ensure that domains ', h('strong','artofmentoring.net, aomapps.au and aomapps.com'),' are enabled for your company firewall. ',
        ]),        
      ]);
      this.$bvModal
        .msgBoxConfirm([messageVNode], {
          title: 'Your Account Status',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Upload my image',
          cancelTitle: 'Get started',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(async value => {
          if(value) {
            return this.$router.push({name: 'participant-application-complete'});
          } else {
            return this.$router.push({name: 'participant-home'});
          }
        });
    },
    firstUnanswerQuestionNonScale() {
      return this.answerData.questions.find(q => {
          let nextQuestion = q.answers.length === 0 && q.type_id !== modelQuestionTypes.SCALE && q.id !== this.$route.params.questionId;
          if (this.currentQuestion) {
            nextQuestion = nextQuestion && q.question_order > this.currentQuestion.question_order;
        }
        return nextQuestion;
      });
    },
    firstUnanswerQuestion() {
      return this.answerData.questions.find(q => {
        let nextQuestion = false;
        if (this.currentQuestion && q.question_order > this.currentQuestion.question_order) {
          if (q.type_id !== modelQuestionTypes.SCALE) {
            nextQuestion = q.answers.length === 0 && q.id !== this.$route.params.questionId;
          } else {
            nextQuestion = q?.statements.find(s => s?.answers && s?.answers.length === 0);
          }
        }
        return nextQuestion;
      });
    },
    lowestRatingScaleQNoAnswer() {
      const ratingQ = this.answerData?.questions?.filter(q => q.type_id === modelQuestionTypes.SCALE);            
      const answeredRatingQ = ratingQ?.filter(q => q.question_order > this.currentQuestion.question_order && q?.statements.find(s => s?.answers && s?.answers.length === 0));
      if(answeredRatingQ && answeredRatingQ.length > 0) {
        return answeredRatingQ.reduce((a, b) => a.question_order < b.question_order ? a : b);
      } 
    },
    highestRatingScaleQNoAnswer() {
      const ratingQ = this.answerData?.questions?.filter(q => q.type_id === modelQuestionTypes.SCALE);            
      const answeredRatingQ = ratingQ?.filter(q => q.question_order > this.currentQuestion.question_order && q?.statements.find(s => s?.answers && s?.answers.length === 0));
      if(answeredRatingQ && answeredRatingQ.length > 0) {
        return answeredRatingQ.reduce((a, b) => a.question_order > b.question_order ? a : b);
      } 
    },
    routeToLowestUnAnsweredQuestion(high = false) {
      
      const lowestQuestionOrder = high ? this.highestRatingScaleQNoAnswer() : this.lowestRatingScaleQNoAnswer();
      const firstUnanswerQuestionNonScale = this.firstUnanswerQuestionNonScale();
      const routeName = Number(this.$route.params.role) === Number(userRoles.MENTOR) ? 'participant-application-mentor' : 'participant-application-mentee';
      const query = Object.assign({}, this.$route.query);
      if(lowestQuestionOrder && firstUnanswerQuestionNonScale) {
        const { id } = [lowestQuestionOrder, firstUnanswerQuestionNonScale].reduce((a, b) => a?.question_order < b?.question_order ? a : b);
        this.$router.replace({ name: routeName, params: {questionId: Number(id), role: this.$route.params.role}, query });
      }
      if(!lowestQuestionOrder && firstUnanswerQuestionNonScale) {
        const { id } = firstUnanswerQuestionNonScale; 
        this.$router.replace({ name: routeName, params: {questionId: Number(id), role: this.$route.params.role}, query });
      }
      if(lowestQuestionOrder && !firstUnanswerQuestionNonScale) {
        const { id } = lowestQuestionOrder; 
        this.$router.replace({ name: routeName, params: {questionId: Number(id), role: this.$route.params.role}, query });
      }
    },
    async loadApplicationAnswersForMentee() {
      if(this.appCurrentProgram?.id && this.applicationMentee?.id && this.profile?.id) {
        await this.$store.dispatch("participants/FETCH_OWN_APPLICATION_ANSWERS", {
          programId: this.appCurrentProgram.id,
          formId: this.applicationMentee.id,
          userId: this.profile.id
        });
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.save-continue-wrapper {
  min-width: 220px
}
.w-1 {
  width: 100%;
}
</style>